import styled from 'styled-components';

const TermsConditionStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .active {
        color: rgba(68, 136, 62, 1);
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1.92px;
        border-bottom: 4px solid rgba(68, 136, 62, 1);
    }

    .tc_link_class > a {
        font-weight: 600;
        color: rgba(38, 111, 197, 1);
        font-family: Lato;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.05px;
        /* line-height: 34px; */
        margin: 30px auto;
        text-decoration: none;
    }
    .terms-condition-img-container {
        position: relative;
        text-align: center;
        color: white;
    }
    .terms-condition-img-container > img {
        width: 100%;
        height: 221px;
        image-rendering: pixelated;
    }

    .terms-condition-img-container > div {
        position: absolute;

        top: 60%;
        left: 405px;
        color: rgba(255, 255, 255, 1);
        font-family: Roboto;
        font-size: 50px;
        font-weight: 500;
        letter-spacing: -0.88px;
        line-height: 66px;
        transform: translate(-50%, -50%);
        @media (min-width: 320px) and (max-width: 620px) {
            left: 150px;
        }
        @media (min-width: 620px) and (max-width: 767px) {
            left: 185px;
        }
       
    }

    .terms-condition-main-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 200px;
    }
    .terms-condition-tab-container {
        display: flex;
        align-items: center;
        width: 55%;
        margin: 50px auto;
        gap: 50px;

        @media (min-width: 400px) and (max-width: 650px) {
            width: 75%;
        }

        @media (max-width: 400px) {
            width: unset;
        }
    }
    .terms-condition-tab-container > div {
        /* width: 30%; */
        text-align: center;
        color: rgba(64, 64, 65, 1);
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1.92px;
        /* line-height: 30px; */
        cursor: pointer;
        line-height: 40px;
    }

    .terms-condition-vertical-line {
        height: 70px;
        width: 1px;
        background-color: rgba(170, 170, 170, 1);
    }
    .terms-condition-section-container {
        width: 55%;
        @media (min-width: 320px) and (max-width: 650px) {
            width: 75%;
        }
        /* margin: 0px auto 150px auto; */
    }

    .terms-condition-section-container > p {
        color: rgba(50, 51, 51, 1);
        font-family: Lato;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 32px;
        margin: 15px auto;
    }

    .terms-condition-grain-heading {
        color: rgba(51, 51, 51, 1);
        font-family: Roboto;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0.03px;
        line-height: 40px;

        @media (min-width: 320px) and (max-width: 650px) {
            font-size: 23px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 34px;
        }
    }
`;

export default TermsConditionStyled;
