import React, { useState } from 'react';
import { withSize } from 'react-sizeme';
import Layout from '../components/Layout';
import TermsConditionStyled from '../components/pageStyles/termsConditions-styles';
import SEO from '../components/SEO';
import useGlobal from '../store';
import ReactHtmlParser from 'react-html-parser';

const TermsAndConditions = ({ path, size }) => {
    const [active, setActive] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const defaultSection = [
        { main: {}, detail: {} },
        { main: {}, detail: {} },
    ];
    const {
        termscondition: {
            tc_details: [
                { main: grain_sales_main },
                { main: feed_sales_main },
            ] = defaultSection,

            tc_hero_image: [{ main: hero_image }] = defaultSection,
        },
    } = globalState;

    const heroImg =
        hero_image?.images &&
        hero_image?.images.length > 0 &&
        hero_image?.images[0];

    return (
        <Layout path={path}>
            <SEO title="CornBids" />
            <TermsConditionStyled>
                {/* <div> */}
                <div className="terms-condition-img-container">
                    <img src={heroImg?.file?.url} alt={heroImg?.custom_data} />
                    <div>{heroImg?.custom_data}</div>
                </div>
                {/* </div> */}

                <div className='terms-condition-main-container' >
                    <div className="terms-condition-tab-container">
                        <div
                            className={`${!active && `active`}`}
                            onClick={() => setActive(false)}
                        >
                            {grain_sales_main?.heading?.text}{' '}
                        </div>
                        <div className="terms-condition-vertical-line">
                            &nbsp;
                        </div>
                        <div
                            className={`${active && `active`}`}
                            onClick={() => setActive(true)}
                        >
                            {feed_sales_main?.heading?.text}
                        </div>
                    </div>
                    <div className="terms-condition-section-container">
                        {!active
                            ? ReactHtmlParser(grain_sales_main?.body?.richtext)
                            : ReactHtmlParser(feed_sales_main?.body?.richtext)}
                    </div>
                </div>
            </TermsConditionStyled>
        </Layout>
    );
};

export default withSize()(TermsAndConditions);
